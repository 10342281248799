<template>
  <div class="login-form">
    <div class="min-h-75px">
      <vue-inline-alert
        v-if="message"
        :message="message"
        message-type="success"
      ></vue-inline-alert>
      <vue-inline-alert
        v-if="errorMessage"
        :message="errorMessage"
        message-type="error"
      ></vue-inline-alert>
    </div>
    <div v-if="token" class="fade-in">
      <div class="pt-lg-0 pt-5">
        <h3 class="bold-text text-dark font-size-h4 font-size-h1-lg">
          Reset Your Password
        </h3>
      </div>

      <v-card :disabled="busy" tile flat class="transparent mt-15">
        <reset-password-form :reset-token="token"></reset-password-form>
      </v-card>
    </div>
    <div v-else>
      <vue-inline-alert
        v-if="tokenError"
        :message="tokenError"
        message-type="error"
        no-close-btn
      ></vue-inline-alert>
      <v-row justify="center" class="mx-auto" v-if="tokenError">
        <v-col class="text-center">
          <v-btn
            color="primary"
            class="text-capitalize"
            @click="$router.push({ name: 'dashboard' })"
            >Go Home</v-btn
          >
        </v-col>
      </v-row>
      <h3
        v-if="!tokenError && !token"
        class="bold-text text-dark font-size-h4 font-size-h1-lg text-center"
      >
        <v-progress-circular
          :size="18"
          width="2"
          color="primary"
          indeterminate
          class="mr-1"
        />
        Verifying the request, please wait...
      </h3>
    </div>
  </div>
</template>

<script>
import { VERIFY_RESET_PASSWORD_TOKEN } from "@/store/users/auth.module";
import ResetPasswordForm from "@/view/components/User/ResetPasswordForm";

export default {
  components: { ResetPasswordForm },
  name: "reset-password",
  data() {
    return {
      form: {
        password: "",
        confirm_password: "",
      },
      message: "",
      errorMessage: null,
      busy: false,
      token: "",
    };
  },
  methods: {
    resetForm() {
      this.form = {
        password: "",
        confirm_password: "",
      };
      this.message = "";
    },
  },
  mounted() {
    this.tokenError = "";
    this.$store
      .dispatch(VERIFY_RESET_PASSWORD_TOKEN, this.$route.params.token)
      .then(({ token }) => {
        this.tokenError = "";
        this.token = token;
      })
      .catch((error) => {
        this.$router.push({
          name: "error",
          params: {
            message: error.message,
          },
        });
      });
  },
};
</script>
