var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "min-h-75px"
  }, [_vm.message ? _c('vue-inline-alert', {
    attrs: {
      "message": _vm.message,
      "message-type": "success"
    }
  }) : _vm._e(), _vm.errorMessage ? _c('vue-inline-alert', {
    attrs: {
      "message": _vm.errorMessage,
      "message-type": "error"
    }
  }) : _vm._e()], 1), _vm.token ? _c('div', {
    staticClass: "fade-in"
  }, [_vm._m(0), _c('v-card', {
    staticClass: "transparent mt-15",
    attrs: {
      "disabled": _vm.busy,
      "tile": "",
      "flat": ""
    }
  }, [_c('reset-password-form', {
    attrs: {
      "reset-token": _vm.token
    }
  })], 1)], 1) : _c('div', [_vm.tokenError ? _c('vue-inline-alert', {
    attrs: {
      "message": _vm.tokenError,
      "message-type": "error",
      "no-close-btn": ""
    }
  }) : _vm._e(), _vm.tokenError ? _c('v-row', {
    staticClass: "mx-auto",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'dashboard'
        });
      }
    }
  }, [_vm._v("Go Home")])], 1)], 1) : _vm._e(), !_vm.tokenError && !_vm.token ? _c('h3', {
    staticClass: "bold-text text-dark font-size-h4 font-size-h1-lg text-center"
  }, [_c('v-progress-circular', {
    staticClass: "mr-1",
    attrs: {
      "size": 18,
      "width": "2",
      "color": "primary",
      "indeterminate": ""
    }
  }), _vm._v(" Verifying the request, please wait... ")], 1) : _vm._e()], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pt-lg-0 pt-5"
  }, [_c('h3', {
    staticClass: "bold-text text-dark font-size-h4 font-size-h1-lg"
  }, [_vm._v(" Reset Your Password ")])]);

}]

export { render, staticRenderFns }